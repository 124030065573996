<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-row>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="tanggal"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tanggal"
                :rules="[(v) => !!v || 'Tanggal dibutuhkan']"
                label="Tanggal"
                required
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="tanggal"
              scrollable
              :max="max_tanggal"
              :min="min_tanggal"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="onTanggalChange(tanggal)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-select
            v-model="jam_between"
            :items="jams"
            item-text="jam"
            item-value="jam"
            label="Jam"
            persistent-hint
            return-object
            single-line
            @change="onJamBetweenChange"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <span v-show="is_menit"
            >Slot antrian yang tersedia {{ menits.length }}</span
          >
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-select
            v-model="jam"
            :items="menits"
            item-text="menit"
            item-value="menit"
            label="Antrian"
            persistent-hint
            return-object
            single-line
            v-show="is_menit"
          ></v-select>
        </v-col>
      </v-row>
      <v-btn
        block
        :disabled="!valid"
        color="primary"
        class="mr-4"
        @click="submit"
      >
        <v-icon dark left>fas fa-save</v-icon>Submit
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "antrian2",
  data: () => ({
    valid: true,
    modal: false,
    jam: null,
    jam_between: null,
    tanggal: null,
    tipe: null,
    min_tanggal: moment().format("YYYY-MM-DD"),
    max_tanggal: moment().add(1, "years").format("YYYY-MM-DD"),
  }),
  computed: {
    ...mapGetters({
      menits: "antrian/menits",
      jams: "antrian/jams",
      is_menit: "antrian/is_menit",
      data: "antrian/data",
    }),
  },
  mounted() {
    this.tipe = this.$route.query.tipe;
    console.log(this.menits);
  },
  methods: {
    onTanggalChange(e) {
      const tanggal_booking = moment(e, "YYYY-MM-DD");
      const tanggal_lahir = this.data.tanggal_lahir;

      const age = tanggal_booking.diff(tanggal_lahir, "years");

      if (age < 17) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Umur anda dari tanggal booking kurang dari 17 tahun",
        });
        this.menu = false;
        this.tanggal_lahir = null;
        return;
      }

      this.$store.commit("antrian/SET_IS_MENIT", false);
      this.jam_between = null;
      const params = {
        tanggal: e,
      };
      this.$store.dispatch("antrian/get_jams", params);
      this.$refs.dialog.save(e);
    },
    onJamBetweenChange(e) {
      console.log(e.jam);
      if (this.tanggal === null || this.tanggal === "") {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Tanggal harus di isi",
        });
        this.jam_between = null;
        return;
      }

      if (this.jam_between === null || this.jam_between === "") {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Jam harus di pilih",
        });
        this.jam_between = null;
        return;
      }

      const params = {
        tanggal: this.tanggal,
        jenis_permohonan: this.tipe,
        jam_between: e.jam,
      };

      this.$store.dispatch("antrian/get_menits", params);
    },
    submit() {
      if (this.jam === null || this.jam === "") {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Harus pilih antrian",
        });
        return;
      }

      if (this.$refs.form.validate()) {
        const data = {
          jenis_permohonan: this.data.jenis_permohonan,
          email: this.data.email,
          nama: this.data.nama,
          jenis_kelamin: this.data.jenis_kelamin,
          tempat_lahir: this.data.tempat_lahir,
          tanggal_lahir: this.data.tanggal_lahir,
          tinggi_badan: parseInt(this.data.tinggi_badan),
          pekerjaan: this.data.pekerjaan,
          golongan_darah: this.data.golongan_darah,
          hp: this.data.hp,
          alamat: this.data.alamat,
          pendidikan_terakhir: this.data.pendidikan_terakhir,
          device_id: this.data.device_id,
          golongan: this.convertGolongan(this.data.golongan),
          jam_between: this.jam_between.jam,
          tanggal: this.tanggal,
          jam: this.jam.menit,
        };
        console.log(data);
        this.$store.dispatch("antrian/create", data);
      }
    },

    convertGolongan(golongan) {
      let s = "[";

      for (let i = 0; i < golongan.length; i++) {
        const d = golongan[i];
        s += "'" + d + "'";
        if (i < golongan.length - 1) {
          s += ",";
        }
      }

      s += "]";

      return s;
    },
  },
};
</script>

<style scoped>
</style>
