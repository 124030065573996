<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        xl="6"
        lg="6"
        v-for="item in items"
        :key="item.id"
      >
        <v-card elevation="6" class="rounded-xl">
          <v-card-text>
            {{ item.nama }}<br />
            {{ item.jenis_kelamin }}<br />
            <span class="font-weight-bold">{{ item.jenis_permohonan }}</span
            ><br />
            <span class="font-weight-bold">{{
              convertToDate(item.tanggal)
            }}</span
            ><br /><br />
            <span class="font-weight-bold">{{ item.jam }}</span>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" :to="`/jadwal/${item.id}?home=false`">Klik Disini</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "jadwal",
  data: () => ({
    width: 720,
    height: 500,
  }),
  computed: {
    ...mapGetters({
      items: "jadwal/items",
    }),
  },
  mounted() {
    const tanggal = moment().format("YYYY-MM-DD");
    const params = {
      device_id: localStorage.getItem("sim-booster:device_id"),
      tanggal: tanggal,
    };

    this.$store.dispatch("jadwal/get_jadwals", params);
  },
  methods: {
    convertToDate(tanggal) {
      const date = moment(tanggal, "YYYY-MM-DD");

      return date.format("dddd, DD MMMM YYYY");
    },
  },
};
</script>

<style scoped>
</style>
