<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="6" xs="6" sm="6" xl="3" md="3"
        lg="3" v-for="jenis in jeniss"
        :key="jenis.jenis">
        
        <v-card elevation="2" class="rounded-xl d-flex align-center">
          <v-card-text class="text-center">
            {{jenis.jenis}}<br/><strong>{{jenis.jumlah}}</strong>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" xs="12" xl="12" lg="12">
        <v-card elevation="6" class="rounded-xl d-flex align-center">
          <v-card-text>
            <highcharts :options="chartOptions"></highcharts>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  name: "home",
  components: {
    highcharts: Chart,
  },
  data: () => ({
    width: 720,
    height: 500,
    jeniss: [],
    chartOptions: {
      chart: {
        type: "column",
      },
      title: {
        text: "Jumlah Permohonan SIM",
      },
      legend: {
        enabled: true,
      },
      series: [
        {
          name: "Baru",
          data: [0],
          color: "#f80707",
          // sample data
        },
        {
          name: "Perpanjangan",
          data: [0],
          color: "#f35a15",
          // sample data
        }
      ],
    },
  }),
  mounted() {
    this.$axios.get(`/jumlah/sim`).then((response) => {
      console.log(response)
      const item = response.data;
      this.chartOptions.series[0].data = [item.vaksin1];
      this.chartOptions.series[1].data = [item.vaksin2];
      this.chartOptions.series[2].data = [item.vaksin3];
      this.chartOptions.series[3].data = [item.anak];

      this.chartOptions.series[0].name = "Vaksin 1 = " + item.vaksin1;
      this.chartOptions.series[1].name = "Vaksin 2 = " + item.vaksin2;
      this.chartOptions.series[2].name = "Vaksin Booster = " + item.vaksin3;
      this.chartOptions.series[3].name = "Anak-anak = " + item.anak;
    });

    this.$axios.get(`/jumlah/jenis`).then((response) => {
      const items = response.data;
      this.jeniss = items;
    });
  },
  methods: {},
};
</script>

<style scoped>
</style>
