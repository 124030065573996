<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="6">
        <v-card elevation="6" class="rounded-xl">
          <v-toolbar color="blue-grey">
            <v-toolbar-title class="white--text">{{
              convertToDate(item.tanggal)
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="4">Nama</v-col>
              <v-col cols="8">: {{ item.nama }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Jenis Kelamin</v-col>
              <v-col cols="8">: {{ item.jenis_kelamin }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Jenis Permohonan</v-col>
              <v-col cols="8">: {{ item.jenis_permohonan }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Golongan</v-col>
              <v-col cols="8">: {{ item.golongan }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="4">Jam</v-col>
              <v-col cols="8">: {{ item.jam }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span class="d-flex justify-center font-weight-bold">
                  Kode Booking
                </span>
                <span class="d-flex justify-center font-weight-bold">
                  <v-btn color="red white--text">{{ item.kode }}</v-btn>
                </span>
                <span class="d-flex justify-center font-weight-bold">
                  <v-img :src="`${host}${item.image_url}`" max-width="200"/>
                </span>
                <span class="d-flex justify-center font-weight-bold">
                  <span class="red--text text-center">Tunjukan kode booking atau qrcode ke petugas pada tanggal booking dan datang sebelum jam booking, jika terlambat maka harus daftar ulang lagi</span>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn v-if="home === 'false'" color="orange white--text" to="/jadwals">Kembali</v-btn>
            <v-btn v-else color="orange white--text" to="/">Home</v-btn>
            <v-btn color="green white--text" @click="print(item.kode)">Simpan PDF</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "detail_jadwal",
  data: (vm) => ({
    host: vm.$host_image,
    home: null,
  }),
  computed: {
    ...mapGetters({
      item: "jadwal/get_item",
    }),
  },
  mounted() {
    this.home = this.$route.query.home;
    const id = this.$route.params.id;
    /*
    const item = this.$store.getters["jadwal/item"](parseInt(id));
    if (item != null || item != undefined) {
      this.item = item;
    }
    */

    this.$store.dispatch("jadwal/get_jadwal", id);
  },
  methods: {
    convertToDate(tanggal) {
      const date = moment(tanggal, "YYYY-MM-DD");
      return date.format("dddd, DD MMMM YYYY");
    },
    print(kode) {
      window.open(`${this.host}api/print/${kode}`)
    }
  },
};
</script>

<style scoped>
</style>
