<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">No Seri</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.seri }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Jenis Permohonan</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.jenis_permohonan }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Golongan SIM</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.golongan }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Email</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.email }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Nama</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.nama }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Jenis Kelamin</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.jenis_kelamin }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Tempat/Tanggal Lahir</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.tempat_lahir }} / {{ convertToBirth(item.tanggal_lahir) }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Tinggi Badan</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.tinggi_badan }} cm</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Pekerjaan</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.pekerjaan }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Hp/Telp</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.hp }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Alamat</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.alamat }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Pendidikan Terakhir</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.pendidikan_terakhir }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Golongan Darah</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ item.golongan_darah }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Tanggal Pembuatan SIM</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: {{ convertToDate(item.tanggal) }}</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Foto Copy KTP</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.is_ktp == 1">Ada</span><span v-else-if="item.is_ktp == 2">Tidak Ada</span><span v-else>Belum Di isi</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Ber kaca mata</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.is_kacamata == 1">Ya</span><span v-else-if="item.is_kacamata == 2">Tidak</span><span v-else>Belum Di isi</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Cacat Fisik</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.is_cacat == 1">Ya</span><span v-else-if="item.is_cacat == 2">Tidak</span><span v-else>Belum Di isi</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Sertifikat Mengemudi</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.is_sertifikat == 1">Ada</span><span v-else-if="item.is_sertifikat == 2">Tidak Ada</span><span v-else>Belum Di isi</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Ujian Teori</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.is_teori == 1">Lulus</span><span v-else-if="item.is_teori == 2">Belum Lulus</span><span v-else>Belum Di isi</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Tanggal Ujian Teori</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.tanggal_teori === '' || item.tanggal_teori === undefined || item.tanggal_teori === 'NULL'">Belum Di isi</span><span v-else>{{ convertToDate(item.tanggal_teori) }}</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Ujian Simulator</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.is_simulator == 1">Lulus</span><span v-else-if="item.is_simulator == 2">Belum Lulus</span><span v-else>Belum Di isi</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Tanggal Ujian Simulator</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.tanggal_simulator === '' || item.tanggal_simulator === undefined || item.tanggal_simulator === 'NULL'">Belum Di isi</span><span v-else>{{ convertToDate(item.tanggal_simulator) }}</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Ujian Praktik I</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.is_praktik1 == 1">Lulus</span><span v-else-if="item.is_praktik1 == 2">Belum Lulus</span><span v-else>Belum Di isi</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Tanggal Ujian Praktik I</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.tanggal_praktik1 === '' || item.tanggal_praktik1 === undefined || item.tanggal_praktik1 === 'NULL'">Belum Di isi</span><span v-else>{{ convertToDate(item.tanggal_praktik1) }}</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Ujian Praktik II</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.is_praktik2 == 1">Lulus</span><span v-else-if="item.is_praktik2 == 2">Belum Lulus</span><span v-else>Belum Di isi</span></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" xs="12" xl="3" lg="3" md="3">Tanggal Ujian Praktik II</v-col>
      <v-col cols="12" sm="12" xs="12" xl="9" lg="9" md="9">: <span v-if="item.tanggal_praktik2 === '' || item.tanggal_praktik2 === undefined || item.tanggal_praktik2 === 'NULL'">Belum Di isi</span><span v-else>{{ convertToDate(item.tanggal_praktik2) }}</span></v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "detail_print",
  data: (vm) => ({
    tanggal: null,
    status: 1,
    host: vm.$host_image,
  }),
  computed: {
    ...mapGetters({
      item: "jadwal/get_item",
    }),
  },
  mounted() {
    const id = this.$route.params.id;

    this.$store.dispatch("jadwal/get_jadwal", id);
  },
  methods: {
    convertToDate(tanggal) {
      const date = moment(tanggal, "YYYY-MM-DD");

      return date.format("dddd, DD MMMM YYYY");
    },
    convertToBirth(tanggal) {
      const date = moment(tanggal, "YYYY-MM-DD");

      return date.format("DD MMMM YYYY");
    },
    onSuccessCopy(e) {
      console.log(e);
    },
    onErrorCopy(e) {
      console.log(e);
    },
  },
};
</script>

<style scoped>
</style>
