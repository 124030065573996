<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          Golongan SIM<br />
          <v-row>
            <v-col cols="4">
              <v-checkbox
                v-model="a"
                label="A"
                color="blue"
                value="a"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="c"
                label="C"
                color="blue"
                value="c"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="b1"
                label="B I"
                color="blue"
                value="b1"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-checkbox
                v-model="b2"
                label="B II"
                color="blue"
                value="b2"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="d"
                label="D"
                color="blue"
                value="d"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="d1"
                label="D I"
                color="blue"
                value="d1"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-checkbox
                v-model="aUmum"
                label="A Umum"
                color="blue"
                value="a_umum"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="b1Umum"
                label="B I Umum"
                color="blue"
                value="b1_umum"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-model="b2Umum"
                label="B II Umum"
                color="blue"
                value="b2_umum"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-text-field
            v-model="email"
            :rules="[(v) => !!v || 'Email dibutuhkan']"
            label="Email"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-text-field
            v-model="nama"
            :rules="[(v) => !!v || 'Nama dibutuhkan']"
            label="Nama"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          Jenis Kelamin<br />
          <v-radio-group v-model="jenis_kelamin" row>
            <v-radio label="Pria" value="Pria"></v-radio>
            <v-radio label="Wanita" value="Wanita"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-text-field
            v-model="tempat_lahir"
            :rules="[(v) => !!v || 'Tempat Lahir dibutuhkan']"
            label="Tempat Lahir"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="tanggal_lahir"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tanggal_lahir"
                :rules="[(v) => !!v || 'Tanggal Lahir dibutuhkan']"
                label="Tanggal Lahir"
                required
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="tanggal_lahir" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(tanggal_lahir)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-text-field
            v-model="tinggi_badan"
            :rules="[(v) => !!v || 'Tinggi Badan dibutuhkan']"
            label="Tinggi Badan"
            type="number"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-text-field
            v-model="pekerjaan"
            :rules="[(v) => !!v || 'Pekerjaan dibutuhkan']"
            label="Pekerjaan"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-text-field
            v-model="hp"
            :rules="[(v) => !!v || 'Telepon/HP dibutuhkan']"
            label="Telepon/HP"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-text-field
            v-model="alamat"
            :rules="[(v) => !!v || 'Alamat dibutuhkan']"
            label="Alamat"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
          <v-text-field
            v-model="pendidikan_terakhir"
            :rules="[(v) => !!v || 'Pendidikan Terakhir dibutuhkan']"
            label="Pendidikan Terakhir"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-btn
        block
        :disabled="!valid"
        color="primary"
        class="mr-4"
        @click="next"
      >
        <v-icon dark left>fas fa-arrow-right</v-icon>Selanjutnya
      </v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "antrian_panjang",
  data: () => ({
    valid: true,
    modal: false,
    a: null,
    c: null,
    b1: null,
    b2: null,
    d: null,
    d1: null,
    b1Umum: null,
    aUmum: null,
    b2Umum: null,
    email: null,
    nama: null,
    jenis_kelamin: null,
    tempat_lahir: null,
    tanggal_lahir: null,
    tinggi_badan: null,
    pekerjaan: null,
    hp: null,
    alamat: null,
    pendidikan_terakhir: null,
    tipe: null,
  }),
  mounted() {
    //this.tipe = this.$route.query.tipe;
    this.tipe = "perpanjangan"
  },
  methods: {
    next() {
      let golongans = [];

      if (
        this.a !== null ||
        this.c !== null ||
        this.b1 !== null ||
        this.b2 !== null ||
        this.d !== null ||
        this.d1 !== null ||
        this.aUmum !== null ||
        this.b1Umum !== null ||
        this.b2Umum !== null
      ) {
        console.log("Golongan SIM sip");
        if (this.a !== null) {
          golongans.push("A");
        }

        if (this.c !== null) {
          golongans.push("C");
        }

        if (this.b1 !== null) {
          golongans.push("B I");
        }

        if (this.b2 !== null) {
          golongans.push("B II");
        }

        if (this.d !== null) {
          golongans.push("D");
        }

        if (this.d1 !== null) {
          golongans.push("D I");
        }

        if (this.aUmum !== null) {
          golongans.push("A Umum");
        }

        if (this.b1Umum !== null) {
          golongans.push("B I Umum");
        }

        if (this.b2Umum !== null) {
          golongans.push("B II Umum");
        }
      } else {
        console.log("Golongan SIM harus di pilih salah satu");
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Golongan SIM harus di pilih salah satu",
        });
        return;
      }

      if (this.jenis_kelamin === null) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Harus pilih jenis kelamin",
        });
        return;
      }

      
      if (this.$refs.form.validate()) {
        const data = {
          jenis_permohonan: this.tipe,
          email: this.email,
          nama: this.nama,
          jenis_kelamin: this.jenis_kelamin,
          tempat_lahir: this.tempat_lahir,
          tanggal_lahir: this.tanggal_lahir,
          tinggi_badan: this.tinggi_badan,
          pekerjaan: this.pekerjaan,
          hp: this.hp,
          alamat: this.alamat,
          pendidikan_terakhir: this.pendidikan_terakhir,
          device_id: localStorage.getItem("sim-booster:device_id"),
          golongan: golongans,
        };
        this.$store.dispatch("antrian/next", data);
      }
    },
  },
};
</script>

<style scoped>
</style>
