<template>
  <div>
    <v-row>
      <v-col cols="12">
        <span class="title-bg">Antrian Ruang Foto</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <span class="title">SIM BARU</span><br /><br /><br />
                <p class="text-center">
                  <span class="antrian-small">{{ antrian_a }}</span>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <span class="title">PERPANJANGAN SIM</span><br /><br /><br />
                <p class="text-center">
                  <span class="antrian-small">{{ antrian_b }}</span>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <span class="title-bg">{{ findType(type) }}</span
                ><br /><br /><br /><br /><br /><br />
                <p class="text-center pt-12 pb-12">
                  <span class="antrian">{{ antrian }}</span>
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <audio ref="audio" controls autoplay muted>
        <source v-for="(item, index) in sounds" :key="index" :src="item" />
      </audio>
    </v-row>
  </div>
</template>

<script>
import eventbus from "../eventbus";

export default {
  data: (vm) => ({
    host: vm.$host_image,
    date: new Date().toISOString().substr(0, 7),
    bulan: "",
    tahun: "",
    playerVars: {
      autoplay: 1,
      loop: 1,
      origin: "http://localhost:8080",
    },
    playerOptions: {
      // videojs options
      autoplay: true,
      muted: true,
    },
    antrian: 0,
    antrian_a: 0,
    antrian_b: 0,
    sounds: [
      `${vm.$host_image}sound/nomor_antrian.mp3`,
      `${vm.$host_image}sound/a.mp3`,
      `${vm.$host_image}sound/satu.mp3`,
      `${vm.$host_image}sound/ruang_foto.mp3`,
    ],
    next: 1,
    nextVideo: 0,
    tipe: "single",
    type: "a",
    src: [],
    videoLoaded: true,
    types: [
      {
        id: "a",
        nama: "SIM BARU",
        kode: "A",
      },
      {
        id: "b",
        nama: "PERPANJANGAN SIM",
        kode: "B",
      },
    ],
  }),
  async mounted() {
    console.log(this.host);

    eventbus.$on("antrian", (payload) => {
      if (payload.status === "foto") {
        this.antrian = `${payload.tipe}-${payload.antrian}`;

        if (payload.tipe === "A") {
          this.antrian_a = this.antrian;
        } else {
          this.antrian_b = this.antrian;
        }

        this.sounds = payload.sounds;
        this.type = payload.tipe;
        //this.updateAntrian(payload.tipe, payload.antrian);
        this.playAudio();
      }
    });
  },
  beforeDestroy() {
    if (this.$refs.audio) {
      this.$refs.audio.removeEventListener("ended", this.repeatAudio);
    }
  },

  methods: {
    play() {
      //this.$refs.playerVideo.play();
      //console.log(this.$refs.playerVideo);
    },
    signIn() {
      this.$router.push("/admin");
    },
    playAudio() {
      if (this.$refs.audio) {
        let audio = this.$refs.audio;
        audio.src = `${this.host}${this.sounds[0]}`;
        console.log(audio.src);
        audio.muted = false;
        audio.play();
        this.next = 1;
        audio.addEventListener("ended", this.repeatAudio);
      }
    },
    repeatAudio() {
      let audio = this.$refs.audio;
      if (!audio) return;
      if (this.next < this.sounds.length) {
        audio.src = `${this.host}${this.sounds[this.next]}`;
        audio.play();
        this.next++;
      } else {
        this.next = 1;
        audio.removeEventListener("ended", this.repeatAudio);
      }
    },

    findType(type) {
      if (type === "A") {
        return "SIM BARU";
      } else {
        return "PERPANJANGAN SIM";
      }
    },
    updateAntrian(tipe, antrian) {
      if (tipe === "a") {
        this.antrian_a = `A${antrian}`;
      } else if (tipe === "b") {
        this.antrian_b = `B${antrian}`;
      } else if (tipe === "c") {
        this.antrian_c = `C${antrian}`;
      }
    },
  },
};
</script>

<style scoped>
.antrian {
  font-size: 150px;
  font-weight: bold;
}

.antrian-small {
  font-size: 100px;
  font-weight: bold;
  margin-top: 60px;
}

.title-bg {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.signin {
  position: absolute;
  bottom: 0;
  left: 0;
}

.marquee {
  font-size: 36px;
  color: white;
}
</style>

