<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
        <v-card
          height="400"
          elevation="6"
          class="rounded-xl d-flex align-center"
        >
          <v-card-text>
            <v-img contain src="../assets/1.jpeg" height="380"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
        <v-card
          height="400"
          elevation="6"
          class="rounded-xl d-flex align-center"
        >
          <v-card-text>
            <v-img contain src="../assets/2.jpeg" height="380"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
        <v-card
          height="400"
          elevation="6"
          class="rounded-xl d-flex align-center"
        >
          <v-card-text>
            <v-img contain src="../assets/3.jpeg" height="380"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
        <v-card
          height="400"
          elevation="6"
          class="rounded-xl d-flex align-center"
        >
          <v-card-text>
            <v-img contain src="../assets/4.jpeg" height="380"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row align="center" justify="center">
      <v-col cols="6" sm="6" xs="6" xl="6" lg="6">
        <v-card
          height="120"
          elevation="6"
          class="rounded-xl d-flex align-center"
          @click="openUrl('polres_jembrana')"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" align="center">
                <img
                  src="../assets/instagram.png"
                  height="100"
                  class="d-none d-lg-flex d-xl-flex"
                />
                <img
                  src="../assets/instagram.png"
                  height="60"
                  class="d-lg-none d-xl-none"
                />
                <br />
                <span class="font-weight-bold text-h6">Polres Jembrana</span>
                <br />
                <span class="font-weight-bold blue--text"
                  >@polres_jembrana</span
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="6" xs="6" xl="6" lg="6">
        <v-card
          height="120"
          elevation="6"
          class="rounded-xl d-flex align-center"
          @click="openUrl('satlantasjembrana')"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" align="center">
                <img
                  src="../assets/instagram.png"
                  height="100"
                  class="d-none d-lg-flex d-xl-flex"
                />
                <img
                  src="../assets/instagram.png"
                  height="60"
                  class="d-lg-none d-xl-none"
                />
                <br />
                <span class="font-weight-bold text-h6">Satlantas Jembrana</span>
                <br />
                <span class="font-weight-bold blue--text"
                  >@satlantasjembrana</span
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
export default {
  name: "informasi",
  data: () => ({
    width: 720,
    height: 500,
    items: [
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/1.jpeg",
        text: "buka chrome lalu ketik alamat berikut : https://simbooster.polresjembrana.com, lalu pilih menu Antrian SIM",
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/2.jpeg",
        text: "lalu pilih SIM Baru jika membuat sim baru atau pilih menu Perpajangan SIM jika mau perpanjangan sim",
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/3.jpeg",
        text: "lalu isi data golongan SIM, email jika ada, nama, jenis kelamin, tempat lahir",
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/4.jpeg",
        text: "isi tanggal lahir, tinggi badan, pekerjaan, no telp/hp, alamat, pendidikan terakhir dan golongan darah lalu klik selanjutnya",
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/5.jpeg",
        text: "lalu pilih tanggal melakukan pelayanan sim, lalu pilih slot antrian antara jam 08:00 - 09:00 atau sesuai dengan jam, pelayanan sim sampai jam 14:00",
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/6.jpeg",
        text: "setelah itu muncul barcode atau no booking, screenshoot atau simpan pdf jika ingin di simpan sebagai pdf",
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/7.jpeg",
        text: "bawa barcode ke petugas pelayanan sim untuk di scan dan mendapatkan no antrian, dan datang sebelum jam booking",
      },
    ],
  }),
  mounted() {},
  methods: {
    openUrl(url) {
      window.open(`https://instagram.com/${url}`);
    },
  },
};
</script>

<style scoped>
</style>
