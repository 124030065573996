<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
        <v-carousel>
          <v-carousel-item
            v-for="(item, i) in items"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <div class="text-center">
                <img :src="item.src" height="350"/><br/>
                <span class="text-xs-title">
                    {{ item.text }}
                </span>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "panduan",
  data: () => ({
    width: 720,
    height: 500,
    items: [
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/1.jpeg",
        text: "buka chrome lalu ketik alamat berikut : https://simbooster.polresjembrana.com, lalu pilih menu Antrian SIM"
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/2.jpeg",
        text: "lalu pilih SIM Baru jika membuat sim baru atau pilih menu Perpajangan SIM jika mau perpanjangan sim"
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/3.jpeg",
        text: "lalu isi data golongan SIM, email jika ada, nama, jenis kelamin, tempat lahir"
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/4.jpeg",
        text: "isi tanggal lahir, tinggi badan, pekerjaan, no telp/hp, alamat, pendidikan terakhir dan golongan darah lalu klik selanjutnya"
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/5.jpeg",
        text: "lalu pilih tanggal melakukan pelayanan sim, lalu pilih slot antrian antara jam 08:00 - 09:00 atau sesuai dengan jam, pelayanan sim sampai jam 14:00"
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/6.jpeg",
        text: "setelah itu muncul barcode atau no booking, screenshoot atau simpan pdf jika ingin di simpan sebagai pdf"
      },
      {
        src: "https://simbooster.polresjembrana.com/upload/panduan/7.jpeg",
        text: "bawa barcode ke petugas pelayanan sim untuk di scan dan mendapatkan no antrian, dan datang sebelum jam booking"
      },
    ],
  }),
  mounted() {},
  methods: {},
};
</script>

<style scoped>
</style>
