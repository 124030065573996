<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        xl="6"
        lg="6"
        v-for="item in items"
        :key="item.id"
      >
        <v-card elevation="6" class="rounded-xl">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                {{ item.nama }}<br />
                {{ item.alamat }}<br />
                {{ item.jenis_permohonan }}<br />
                {{ item.golongan }}<br />
                {{ convertToDate(item.tanggal) }}<br />
                {{ item.jam }}<br />
                <span class="text-h3">{{
                  convertKode(item.kode, item.jenis_permohonan)
                }}</span
                ><br />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="orange" :disabled="!item.is_print == 1" class="white--text" @click="next(item.kode, item.jenis_permohonan)"
              >{{ getJenis(item.jenis_permohonan) }}</v-btn
            >
            <v-btn
              color="primary"
              @click="panggil(item.kode, item.jenis_permohonan)"
              >Panggil</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import eventbus from "../../eventbus";

export default {
  name: "foto",
  data: (vm) => ({
    tanggal: null,
    status: 3,
    host: vm.$host_image,
  }),
  computed: {
    ...mapGetters({
      items: "jadwal/antrian_aviss",
    }),
  },
  mounted() {
    this.tanggal = moment();
    const tanggal = this.tanggal.format("YYYY-MM-DD");

    const params = {
      status: this.status,
      tanggal: tanggal,
    };

    this.$store.dispatch("jadwal/get_jadwal_antrian_aviss", params);

    

    eventbus.$on("antrian_avis", (payload) => {
      this.$store.commit("jadwal/ADD_ANTRIAN_AVISS", payload);
    });

  },
  methods: {
    convertToDate(tanggal) {
      const date = moment(tanggal, "YYYY-MM-DD");

      return date.format("dddd, DD MMMM YYYY");
    },
    getJenis(jenis_permohonan) {
      if (jenis_permohonan == "baru") {
        return "Next";
      } else {
        return "Finish";
      }
    },
    convertKode(kode, jenis_permohonan) {
      if (jenis_permohonan === "baru") {
        const text = kode.split("X");
        return text[0];
      } else {
        const text = kode.split("Y");
        return text[0];
      }
    },
    panggil(kode, jenis_permohonan) {
      const no = this.convertKode(kode, jenis_permohonan);
      const item = {
        no: no,
      };

      this.$store.dispatch("antrian/avis", item);
    },
    next(kode, jenis_permohonan) {
      let status = 4;
      if (jenis_permohonan == "perpanjangan") {
        status = 5;
      }
      const item = {
        kode: kode,
        status: status,
      };
      this.$store
        .dispatch("jadwal/finish_antrian", item)
        .then((item) => {
          this.$store.commit("jadwal/REMOVE_ANTRIAN_AVISS", item);
        });
    },
  },
};
</script>

<style scoped>
</style>
