<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" xs="12" sm="12" lg="6" xl="6">
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="filterCari"
              :items="filters"
              menu-props="auto"
              item-text="nama"
              item-value="id"
              label="Filter"
              hide-details
              prepend-icon="fas fa-filter"
              single-line
            ></v-select>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="searchText"
              :placeholder="'Cari'"
              rounded
              solo
              prepend-inner-icon="fas fa-search"
              :append-icon="isSearch ? 'fas fa-times' : ''"
              single-line
              @keydown.enter="cari()"
              @click:append="refresh()"
              @click:prepend-inner="refresh()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Jenis Permohonan</th>
                <th class="text-left">Golongan SIM</th>
                <th class="text-left">Tidak Lulus</th>
                <th class="text-left">Nama</th>
                <th class="text-left">Alamat</th>
                <th class="text-left">Tanggal Kembali</th>
                <th class="text-left">HP</th>
                <th class="text-left">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.jenis_permohonan }}</td>
                <td>{{ item.golongan }}</td>
                <td>{{ convertLulus(item.ulang) }}</td>
                <td>{{ item.nama }}</td>
                <td>{{ item.alamat }}</td>
                <td>{{ convertToDate(item.tanggal) }}</td>
                <td>{{ item.hp }}</td>
                <td>
                  <v-btn
                    elevation="2"
                    icon
                    color="primary"
                    :to="`/admin/antrian/print/detail/${item.id}`"
                  >
                    <v-icon small>fas fa-info</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "print",
  data: (vm) => ({
    tanggal: null,
    status: 5,
    host: vm.$host_image,
    filters: [
      {
        id: "seri",
        nama: "No Seri",
      },
      {
        id: "nama",
        nama: "Nama",
      },
    ],
    filterCari: "nama",
    searchText: "",
    isSearch: false,
  }),
  computed: {
    ...mapGetters({
      items: "jadwal/alls",
    }),
  },
  mounted() {
    this.refresh();
  },
  methods: {
    convertToDate(tanggal) {
      const date = moment(tanggal, "YYYY-MM-DD");

      return date.format("dddd, DD MMMM YYYY");
    },
    convertKode(kode, jenis_permohonan) {
      if (jenis_permohonan === "baru") {
        const text = kode.split("X");
        return text[0];
      } else {
        const text = kode.split("Y");
        return text[0];
      }
    },
    convertLulus(ulang) {
      if (ulang === 1) {
        return "Ujian Teori";
      } else if (ulang == 2) {
        return "Ujian Praktek";
      } else {
        "Lulus";
      }
    },
    refresh() {
      const params = {
        status: this.status,
      };

      this.$store.dispatch("jadwal/get_jadwal_antrian_lulus", params);
    },
    cari() {
      let params = {
        status: this.status,
      };

      if (this.searchText !== "") {
        console.log("SEARCH")
        params = {
          status: this.status,
          filter: this.filterCari,
          cari: this.searchText,
        };
      }

      this.$store.dispatch("jadwal/get_jadwal_antrian_lulus", params);
    },
  },
};
</script>

<style scoped>
</style>
