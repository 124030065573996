<template>
  <div>
    Dari Jam 08:00 - 09:00
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        xl="6"
        lg="6"
        v-for="item in items_8"
        :key="item.id"
      >
        <v-card elevation="6" class="rounded-xl">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                {{ item.nama }}<br />
                {{ item.alamat }}<br />
                {{ item.jenis_permohonan }}<br />
                {{ item.golongan }}<br />
                {{ convertToDate(item.tanggal) }}<br />
                {{ item.jam }}<br />
                <span class="text-h3">{{
                  convertKode(item.kode, item.jenis_permohonan)
                }}</span
                ><br />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="orange" :disabled="!item.is_print == 1" class="white--text" @click="next(item.kode)"
              >Next</v-btn
            >
            <v-btn color="success" @click="print(item.kode, item.seri, item.id, item.jam_between)">Print</v-btn>
            <v-btn
              color="primary"
              @click="panggil(item.kode, item.jenis_permohonan)"
              >Panggil</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <br />
    Dari Jam 09:00 - 10:00
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        xl="6"
        lg="6"
        v-for="item in items_9"
        :key="item.id"
      >
        <v-card elevation="6" class="rounded-xl">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                {{ item.nama }}<br />
                {{ item.alamat }}<br />
                {{ item.jenis_permohonan }}<br />
                {{ item.golongan }}<br />
                {{ convertToDate(item.tanggal) }}<br />
                {{ item.jam }}<br />
                <span class="text-h3">{{
                  convertKode(item.kode, item.jenis_permohonan)
                }}</span
                ><br />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="orange" :disabled="!item.is_print == 1" class="white--text" @click="next(item.kode)"
              >Next</v-btn
            >
            <v-btn color="success" @click="print(item.kode, item.seri, item.id, item.jam_between)">Print</v-btn>
            <v-btn
              color="primary"
              @click="panggil(item.kode, item.jenis_permohonan)"
              >Panggil</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <br />
    Dari Jam 10:00 - 11:00
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        xl="6"
        lg="6"
        v-for="item in items_10"
        :key="item.id"
      >
        <v-card elevation="6" class="rounded-xl">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                {{ item.nama }}<br />
                {{ item.alamat }}<br />
                {{ item.jenis_permohonan }}<br />
                {{ item.golongan }}<br />
                {{ convertToDate(item.tanggal) }}<br />
                {{ item.jam }}<br />
                <span class="text-h3">{{
                  convertKode(item.kode, item.jenis_permohonan)
                }}</span
                ><br />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="orange" :disabled="!item.is_print == 1" class="white--text" @click="next(item.kode)"
              >Next</v-btn
            >
            <v-btn color="success" @click="print(item.kode, item.seri, item.id, item.jam_between)">Print</v-btn>
            <v-btn
              color="primary"
              @click="panggil(item.kode, item.jenis_permohonan)"
              >Panggil</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <br />
    Dari Jam 11:00 - 12:00
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        xl="6"
        lg="6"
        v-for="item in items_11"
        :key="item.id"
      >
        <v-card elevation="6" class="rounded-xl">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                {{ item.nama }}<br />
                {{ item.alamat }}<br />
                {{ item.jenis_permohonan }}<br />
                {{ item.golongan }}<br />
                {{ convertToDate(item.tanggal) }}<br />
                {{ item.jam }}<br />
                <span class="text-h3">{{
                  convertKode(item.kode, item.jenis_permohonan)
                }}</span
                ><br />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="orange" :disabled="!item.is_print == 1" class="white--text" @click="next(item.kode)"
              >Next</v-btn
            >
            <v-btn color="success" @click="print(item.kode, item.seri, item.id, item.jam_between)">Print</v-btn>
            <v-btn
              color="primary"
              @click="panggil(item.kode, item.jenis_permohonan)"
              >Panggil</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <br />
    Dari Jam 12:00 - 13:00
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        xl="6"
        lg="6"
        v-for="item in items_12"
        :key="item.id"
      >
        <v-card elevation="6" class="rounded-xl">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                {{ item.nama }}<br />
                {{ item.alamat }}<br />
                {{ item.jenis_permohonan }}<br />
                {{ item.golongan }}<br />
                {{ convertToDate(item.tanggal) }}<br />
                {{ item.jam }}<br />
                <span class="text-h3">{{
                  convertKode(item.kode, item.jenis_permohonan)
                }}</span
                ><br />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="orange" :disabled="!item.is_print == 1" class="white--text" @click="next(item.kode)"
              >Next</v-btn
            >
            <v-btn color="success" @click="print(item.kode, item.seri, item.id, item.jam_between)">Print</v-btn>
            <v-btn
              color="primary"
              @click="panggil(item.kode, item.jenis_permohonan)"
              >Panggil</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <br />
    Dari Jam 13:00 - 14:00
    <v-row>
      <v-col
        cols="12"
        sm="12"
        xs="12"
        xl="6"
        lg="6"
        v-for="item in items_13"
        :key="item.id"
      >
        <v-card elevation="6" class="rounded-xl">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                {{ item.nama }}<br />
                {{ item.alamat }}<br />
                {{ item.jenis_permohonan }}<br />
                {{ item.golongan }}<br />
                {{ convertToDate(item.tanggal) }}<br />
                {{ item.jam }}<br />
                <span class="text-h3">{{
                  convertKode(item.kode, item.jenis_permohonan)
                }}</span
                ><br />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="orange" :disabled="!item.is_print == 1" class="white--text" @click="next(item.kode)"
              >Next</v-btn
            >
            <v-btn color="success" @click="print(item.kode, item.seri, item.id, item.jam_between)"
              >Print</v-btn
            >
            <v-btn
              color="primary"
              @click="panggil(item.kode, item.jenis_permohonan)"
              >Panggil</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import eventbus from "../../eventbus";

export default {
  name: "panggil",
  data: (vm) => ({
    tanggal: null,
    status: 1,
    host: vm.$host_image,
  }),
  computed: {
    ...mapGetters({
      items: "jadwal/antrian_calls",
      items_8: "jadwal/antrian_8",
      items_9: "jadwal/antrian_9",
      items_10: "jadwal/antrian_10",
      items_11: "jadwal/antrian_11",
      items_12: "jadwal/antrian_12",
      items_13: "jadwal/antrian_13",
    }),
  },
  mounted() {
    this.tanggal = moment();
    const tanggal = this.tanggal.format("YYYY-MM-DD");

    const params = {
      status: this.status,
      tanggal: tanggal,
    };

    this.$store.dispatch("jadwal/get_jadwal_antrian_calls", params);

    eventbus.$on("antrian_call", (payload) => {
      this.$store.commit("jadwal/ADD_ANTRIAN_CALLS", payload);
    });
  },
  methods: {
    convertToDate(tanggal) {
      const date = moment(tanggal, "YYYY-MM-DD");

      return date.format("dddd, DD MMMM YYYY");
    },
    convertKode(kode, jenis_permohonan) {
      if (jenis_permohonan === "baru") {
        const text = kode.split("X");
        return text[0];
      } else {
        const text = kode.split("Y");
        return text[0];
      }
    },
    panggil(kode, jenis_permohonan) {
      const no = this.convertKode(kode, jenis_permohonan);
      const item = {
        no: no,
      };

      this.$store.dispatch("antrian/panggil", item);
    },
    print(kode, seri, id, jam_between) {
      console.log(seri)
      if (seri === "" || seri === undefined) {
        /*
        this.$swal.fire({
          title: "Isi no seri",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Submit",
          showLoaderOnConfirm: true,
          preConfirm: (no_seri) => {
            const item = {
              kode: kode,
              seri: no_seri,
              id: id,
              jam_between: jam_between
            };
            this.$store
              .dispatch("jadwal/update_antrian_by_seri", item)
              .then((item) => {
                console.log(item)
                this.$store.commit("jadwal/UPDATE_ANTRIAN_CALLS", item);
                const token = sessionStorage.getItem(
                  "logistic-polres-jembrana:token"
                );
                window.open(
                  `${this.host}api/query/print/${kode}?token=${token}`
                );
              });
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        });
        */
        var no_seri = prompt("Isi no seri")
        if (no_seri !== null || no_seri != undefined) {
          if (no_seri !== "") {
            const item = {
              kode: kode,
              seri: no_seri,
              id: id,
              jam_between: jam_between
            };
            this.$store
              .dispatch("jadwal/update_antrian_by_seri", item)
              .then((item) => {
                console.log(item)
                this.$store.commit("jadwal/UPDATE_ANTRIAN_CALLS", item);
                const token = sessionStorage.getItem(
                  "logistic-polres-jembrana:token"
                );
                window.open(
                  `${this.host}api/query/print/${kode}?token=${token}`
                );
              });
          } else {
            alert("No seri harus di isi")
          }
          
        }
        
      } else {
        const token = sessionStorage.getItem("logistic-polres-jembrana:token");
        window.open(`${this.host}api/query/print/${kode}?token=${token}`);
        //print(`${this.host}api/query/print/${kode}?token=${token}`);
      }
    },
    next(kode) {
      const item = {
        kode: kode,
        status: 2,
      };
      this.$store
        .dispatch("jadwal/next_antrian_to_foto", item)
        .then((item) => {
          this.$store.commit("jadwal/REMOVE_ANTRIAN_CALLS", item);
        });
    },
  },
};
</script>

<style scoped>
</style>
