<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
        <v-card
          height="250"
          elevation="6"
          class="rounded-xl d-flex align-center"
          to="/antrian1?tipe=baru&edit=false"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" align="center">
                <v-icon size="80">fas fa-tags</v-icon>
                <br /><br />
                <span class="font-weight-bold text-h5">SIM Baru</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
        <v-card
          height="250"
          elevation="6"
          class="rounded-xl d-flex align-center"
          to="/antrian1?tipe=perpanjangan&edit=false"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" align="center">
                <v-icon size="80">fas fa-id-badge</v-icon>
                <br /><br />
                <br />
                <span class="font-weight-bold text-h5">Perpanjangan SIM</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "home",
  data: () => ({
    width: 720,
    height: 500,
  }),
  mounted() {},
  methods: {},
};
</script>

<style scoped>
</style>
