<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" xs="12" xl="6" lg="6">
        <v-card
          height="250"
          elevation="6"
          class="rounded-xl d-flex align-center"
          to="/pilihan"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" align="center">
                <img src="../assets/antrian.png" height="120" class="d-none d-lg-flex d-xl-flex" />
                <img src="../assets/antrian.png" height="80" class="d-lg-none d-xl-none" />
                <br />
                <span class="font-weight-bold text-h5">Antrian SIM</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card
          height="250"
          elevation="6"
          class="rounded-xl d-flex align-center"
          to="/informasi"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" align="center">
                <img src="../assets/informasi.png" height="120" class="d-none d-lg-flex d-xl-flex"/>
                <img src="../assets/informasi.png" height="80"  class="d-lg-none d-xl-none"/>

                <br />
                <span class="font-weight-bold text-h5">Informasi</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">

         <v-card
          height="250"
          elevation="6"
          class="rounded-xl d-flex align-center"
          to="/panduan"
        >
          <v-card-text>
            <v-row>
              <v-col cols="12" align="center">
                <img src="../assets/panduan.png" height="120" class="d-none d-lg-flex d-xl-flex"/>
                <img src="../assets/panduan.png" height="80"  class="d-lg-none d-xl-none"/>
                <br />
                <span class="font-weight-bold text-h5">Panduan</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "home",
  data: () => ({
    width: 720,
    height: 500,
  }),
  mounted() {},
  methods: {},
};
</script>

<style scoped>
</style>
